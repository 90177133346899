import React, { useEffect, useState, Fragment } from "react"
import Layout from "../components/Layout"
import TitleBlock from "../components/TitleBlock"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import { Link } from "gatsby"
import Pagination from "../components/Pagination"
import News from "../components/News/NewsItem"
import { useAxiosClient } from "../service/useAxiosClient"
import TitlePage from "../components/TitlePage"

export default function EfpaEuropa({ location }) {
  const [publicationNews, setPublicationNews] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const defaultImage = "/imgs/default-image.jpg"

  const [{ data: dataPublicationsNews }] = useAxiosClient({
    url: `/publications/news/?category=10&page=${currentPage}&nitems=8`,
    method: "GET",
  })

  useEffect(() => {
    if (
      dataPublicationsNews?.items &&
      Array.isArray(dataPublicationsNews?.items)
    ) {
      setPublicationNews(dataPublicationsNews?.items)
      setTotalPages(dataPublicationsNews.total_pages)
      console.log(`dataPublicationsNews`, dataPublicationsNews.items)
    }
  }, [dataPublicationsNews])

  const path = [
    { label: "Inicio", to: "/" },
    { label: "Quiénes somos - EFPA Europa" },
  ]

  const getPage = page => {
    setCurrentPage(page)
  }

  const renderNews = () => {
    return (
      publicationNews &&
      publicationNews.map(item => {
        return (
          <div className="col-12 col-md-6 pl-0">
            <News
              image={item?.block1_image || defaultImage}
              date={item?.publication_date}
              title={item?.title}
              link={`/actualidad/${item?.category.slug}/${item?.slug}`}
              id={item?.id}
            />
          </div>
        )
      })
    )
  }

  const renderPagination = () => {
    if (totalPages <= 1) {
      return (
        <div className="EfpaEurope-news">
          <div className="row efpa-row">{renderNews()}</div>
        </div>
      )
    }

    return (
      <Fragment>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          getPage={getPage}
        />
        <div className="EfpaEurope-news">
          <div className="row efpa-row">{renderNews()}</div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          getPage={getPage}
        />
      </Fragment>
    )
  }

  return (
    <Layout>
      <TitlePage title="EFPA Europa" url={location.pathname} />
      <Hero image="/imgs/quienes-somos.jpg" text="Quiénes somos" />
      <div className="container950">
        <Breadcrumb className="EfpaEurope-breadcrumb" path={path} />
        <div className="row efpa-row">
          <div className="col-12 Assosiation-col">
            <TitleBlock title="EFPA Europa" icon="fas fa-chart-pie" />
            <div className="EfpaEurope">
              <div className="EfpaEurope-description">
                <span className="EfpaEurope-text">
                  La Asociación Europea de Planificación Financiera (EFPA, según
                  sus siglas en inglés) fue creada en el año 2000 como una
                  iniciativa de autorregulación en los servicios financieros
                  ante:
                </span>
                <ul className="EfpaEurope-list">
                  <li className="EfpaEurope-item">
                    El espectacular crecimiento de los servicios de banca
                    personal, privada, de particulares, asesoría financiera etc.
                  </li>
                  <li className="EfpaEurope-item">
                    Las iniciativas de la Unión Europea para reforzar la
                    protección del usuario bancario y financiero, y para crear
                    un mercado único de servicios financieros.
                  </li>
                  <li className="EfpaEurope-item">
                    La amenaza de una extensa regulación europea en servicios
                    financieros en general (FASP, MiFID, IMD, etc) y del
                    asesoramiento financiero, en particular.
                  </li>
                  <li className="EfpaEurope-item">
                    La voluntad de ofrecer una respuesta profesional.
                  </li>
                </ul>
                <Link className="EfpaEurope-link" to="http://www.efpa-eu.org/">
                  <i className="fas fa-check-square"></i>http://www.efpa-eu.org
                </Link>
                {renderPagination()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
